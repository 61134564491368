import React, { useContext, useState } from 'react';
import { Box, Button, Header, Text, Menu, Nav, ResponsiveContext, Tip } from 'grommet';
import { Hpe as HpeIcon, Slack as SlackIcon, Menu as MenuIcon } from 'grommet-icons';

function goNav(url) {
  window.parent.location.href = url;
}

const baseItems = [
  { label: 'Home', 
    onClick: () => { goNav('/') } ,
    tooltip: 'Status Central home...'  
  },
  { label: 'History', 
    onClick: () => { goNav('/history') } ,
    tooltip: "View complete history..." 
  },
];


const AppHeader = () => {

  const size = useContext(ResponsiveContext);
  const [focused] = useState(false);

  // Get the current domain name...
  const domainName = window.location.hostname;

  // Domain specific handling...
  let headerText;
  let items = [...baseItems];

  function appendInternalHeaders() {
    items.push(
      { 
        label: 'IT Support', 
        onClick: () => { goNav('https://myitsupport.ext.hpe.com/ITSHome') },
        tooltip: 'myITsupport site...' 
      },
      { 
        icon: (<SlackIcon color="plain" size="large" />),
        onClick: () => { goNav('slack://channel?team=E01LD9FH0JZ&id=C02N0QT284A') },
        tooltip: 'myITsupport Slack channel...' 
      }
    );
  }  

  switch (domainName) {
    case 'internal.hpe-status.com':
      headerText = 'Status Central- Internal';
      appendInternalHeaders();
      break;      
    case 'internal-itg.hpe-status.com':
      headerText = 'Status Central- Internal ITG';
      appendInternalHeaders();
      break;
    case 'iam.hpe-status.com':
      headerText = 'Status Central- IAM';
      break;      
    case 'iam-itg.hpe-status.com':
      headerText = 'Status Central- IAM ITG';
      break;
    case 'workplace.hpe-status.com':
      headerText = 'Status Central- Global Workplace';
      break;      
    case 'workplace-itg.hpe-status.com':
      headerText = 'Status Central- Global Workplace ITG';
      break;
    case 'it-itg.hpe-status.com':
      headerText = 'Status Central ITG';
      break;      
    default:
      headerText = 'Status Central'; 
      break;
  }

  return (
    <Header fill="horizontal" pad={{ horizontal: 'medium' }} background="background-front">
      <Tip content="Status Central home...">
        <Button onClick={() => { goNav("/"); }}>
          <Box direction="row" align="start" gap="medium" pad={{ vertical: 'small' }} responsive={false}>
            <Box height="24px" justify="center">
              <HpeIcon color="brand"/>
            </Box>
            {(!['xsmall', 'small'].includes(size) ||
              (['xsmall', 'small'].includes(size) && !focused)) && (
              <Box direction="row" gap="xsmall" wrap>
                <Text color="text-strong" weight="bold">HPE</Text>
                <Text color="text-strong">{headerText}</Text>
              </Box>
            )}
          </Box>
        </Button>
      </Tip>
      {!['xsmall', 'small'].includes(size) ? (
        <Nav direction="row" gap="small">
          {items.map(item => (
            <Tip content={item.tooltip} key={item.label}>
              <Button label={item.label}
                      icon={item.icon}
                      key={item.label} 
                      onClick={item.onClick}/>
            </Tip>  
          ))}
        </Nav>
      ) : (
        <Menu icon={<MenuIcon/>} items={items} /> 
      )}
    </Header>
  );
};

export { AppHeader as Header };
